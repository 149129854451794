/**
 * The Root container
 */
import React from "react";
import { connect, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router";
import { Route, Switch } from "react-router-dom";
import { StickyContainer } from "react-sticky";
import Loadable from "react-loadable";
import { useSsrEffect } from "@issr/core";
import { useWeb3React } from "@web3-react/core";
import FourOFour from "./FourOFour";
import Navbar from "../components/Navbar";
import { isServer } from "../redux/store";
import ProjectOnboarding from "../components/ProjectOnboarding";
import { showProject } from "../redux/projects";
import { useEffect } from "react";
import Footer from "../components/Footer";
import { REACT_APP_PROJECT_ID } from "../utils/env";

/**
 * Loadable import for Home container
 */

const UnlockableContentsRoot = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "UnlockableContentsRoot" */ "./UnlockableContents/UnlockableContentsRoot"
    ),
  loading: () => null,
  modules: ["UnlockableContentsRoot"],
});

// const TokensRoot = Loadable({
//   loader: () =>
//     import(/* webpackChunkName: "TokensRoot" */ "./Tokens/TokensRoot"),
//   loading: () => null,
//   modules: ["TokensRoot"],
// });

const MintingSectionRoot = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "MintingSection" */ "./Minting/MintingSectionRoot"
    ),
  loading: () => null,
  modules: ["MintingSection"],
});

const TermsOfServiceRoot = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "TermsOfService" */ "./TermsOfService/TermsOfServiceRoot"
    ),
  loading: () => null,
  modules: ["TermsOfService"],
});

const Collections = Loadable({
  loader: () => import(/* webpackChunkName: "Collections" */ "./Collections"),
  loading: () => null,
  modules: ["Collections"],
});

// DON'T DELETE THE FOLLOWING COMMENT: NEEDED FOR UPDATING THIS FILE WITH THE SCAFFOLD SCRIPT
// new scaffolded loadable imports

/**
 * The Root container used as entry point for the react-router.
 * All routes are lazy-loaded with Loadable.
 */
function Root() {
  const { account } = useWeb3React();
  if (!isServer) window.account = account;

  const dispatch = useDispatch();
  const dispatchShowProject = async () => {
    dispatch(showProject(REACT_APP_PROJECT_ID));
  };

  useSsrEffect(dispatchShowProject);
  useEffect(dispatchShowProject, [dispatch, REACT_APP_PROJECT_ID]);

  return (
    <StickyContainer id="Root">
      <Navbar />
      {account && <ProjectOnboarding />}
      <div id="content">
        <Switch>
          <Route path="/" exact component={MintingSectionRoot} />
          <Route path="/contents" component={UnlockableContentsRoot} />
          <Route path="/terms" component={TermsOfServiceRoot} />
          <Route path="/collections" component={Collections} />
          {/* <Route path="/tokens" component={TokensRoot} /> */}

          {/* // DON'T DELETE THE FOLLOWING COMMENT: NEEDED FOR UPDATING THIS FILE WITH THE SCAFFOLD SCRIPT */}
          {/* new routes */}

          <Route component={FourOFour} />
        </Switch>
      </div>
      <Footer />
    </StickyContainer>
  );
}

/**
 * Selects props from the store and passes them to the component
 * @param {Object} state the store
 */
const mapStateToProps = () => ({});
/**
 * Binds dispatch method to the actions passed as props to the component
 */
const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

/** export the redux connected component and add a frontload call */
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Root));
