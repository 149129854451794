/**
 * Local Storage utils
 * - functions to load and save the persisted local storage
 */

/**
 * getter for the store state
 */
export const loadState = () => {
  try {
    const serializedState = localStorage.getItem("state");
    const parsedState = JSON.parse(serializedState);

    if (window && window.SSR_DATA) {
      return { ...window.SSR_DATA }; // TODO: merge states!!
    }
    return parsedState || {};
  } catch (err) {
    console.log("loadState Error", err);
    return undefined;
  }
};

/** setter for the store state */
export const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("state", serializedState);
    if (state && state.i18nState && state.i18nState.lang) {
      localStorage.setItem("lang", state.i18nState.lang);
    }
  } catch (err) {
    // Ignore
  }
};
