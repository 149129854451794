/**
 *  Sagas for the content resource managment using Swagger client
 *
 * Each saga watcher intercepts a trigger action, does the asyncrhonous work in the respective worker saga and dispatches a success or a failure action.
 * Fetch calls are made via the swagger tags interface requests
 *
 */

import { call, put, takeEvery, select } from "redux-saga/effects";
import buildHeaders from "../../../utils/buildHeaders";
import { actions } from "./index";
import apiClient, { applyHeaders } from "../utils/apiSwaggerRequest";
import { storeIncluded } from "../utils/dictionarySagas";
const projectId = process.env.REACT_APP_PROJECT_ID;

/** Worker Sagas */

/** Show Saga
 *  @description: connects to the showStaticPage operation
 *  @param {number} action.payload the content id
 */
export function* show(action) {
  const { id, token } = action.payload;
  const lang = yield select((state) => state.i18nState.lang);
  let headers = buildHeaders();
  headers["Accept-Language"] = lang;
  headers["wallet-token"] = token;
  try {
    const SwaggerClient = yield call(apiClient);
    const payload = yield call(
      SwaggerClient.apis.Aliases.showCase,
      { id: id },
      { requestInterceptor: applyHeaders(headers) }
    );
    if (payload.obj?.included) {
      yield* storeIncluded({ payload: payload.obj.included });
    }
    yield put({ type: actions.showSuccess, payload: payload.obj });
  } catch (e) {
    yield put({ type: actions.showFail, payload: e });
  }
}

/** Update Saga --> needs fix, we dont use swagger client for this endpoint
 *  @description: connects to the updateCase operation. If faqcategory contains a picture also connects to the updateCase operation
 *  @param {Object} action.payload the alias to update
 */
export function* update(action) {
  const { id, token } = action.payload;
  const lang = yield select((state) => state.i18nState.lang);

  let headers = buildHeaders();
  headers["Accept-Language"] = lang;
  headers["wallet-token"] = token;
  try {
    const SwaggerClient = yield call(apiClient);

    const payload = yield call(
      SwaggerClient.apis.Aliases.updateCase,
      { id: id },
      {
        requestInterceptor: applyHeaders(headers),
        requestBody: {
          // enabled: boolean,
          // share_real_email: boolean,
          project_id: projectId
        },
      }
    );
    if (payload.obj?.included) {
      yield* storeIncluded({ payload: payload.obj.included });
    }
    yield put({ type: actions.updateSuccess, payload: payload.obj });
  } catch (e) {
    yield put({ type: actions.updateFail, payload: e });
  }
}

/**
 * Saga Watchers
 * The exported list of sagas registered. When one of the action types is dispatched
 * the related worker saga is invoked.
 * Each saga is executed in a different thread
 */
function* aliasesSaga() {
  yield takeEvery(actions.show, show);
  yield takeEvery(actions.update, update);
}
export default aliasesSaga;
