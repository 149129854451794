/** ----- buildHeaders.js -----
 *  File: buildHeaders.js
 *
 *  Purpose: providing helper functions to set headers for api requests
 *
 *-----------------------------*/
import Cookies from "universal-cookie";
// import { hooks as metaMaskHooks } from "../connectors/metaMask";
// import { hooks as walletConnectHooks } from "../connectors/walletConnect";
// import { hooks as bowlineWalletHooks } from "../connectors/bowlineConnect";

// function getAccount() {
//   const metamaskAccounts = metaMaskHooks.useAccounts();
//   if (metamaskAccounts && metamaskAccounts.length > 0) {
//     return metamaskAccounts[0];
//   }
//   const walletConnectAccounts = walletConnectHooks.useAccounts();
//   if (walletConnectAccounts && walletConnectAccounts.length > 0) {
//     return walletConnectAccounts[0];
//   }
//   const bowlineAccounts = bowlineWalletHooks.useAccounts();
//   if (bowlineAccounts && bowlineAccounts.length > 0) {
//     return bowlineAccounts[0];
//   }

//   return null;
// }

const cookieHandler = new Cookies();

async function buildHeaders() {
  let creatorToken = cookieHandler.get("creator-token");
  let walletToken = cookieHandler.get("wallet-token");
  let signer = null;
  try {
    signer = window.account;
  } catch (e) {
    console.log("Account missing missing");
  }
  const headers = {
    "Content-Type": "application/json",
  };
  if (creatorToken) headers["creator-token"] = creatorToken;
  if (walletToken) headers["wallet-token"] = walletToken;
  if (signer) headers["uid"] = signer;
  return headers;
}

export default buildHeaders;
