/** Worker Sagas */

import { call, put, takeEvery } from "redux-saga/effects";
import buildHeaders from "../../utils/buildHeaders";
import { actions } from "./index";
import { storeIncluded } from "../dictionarySagas";
import apiClient, { applyHeaders } from "../../utils/apiSwaggerRequest";

/** List Saga
 *  @description: connects to the getUnlockableContent operation
 */
export function* list(action) {
  let headers = yield buildHeaders();
  let {
    projectId,
    pageNum,
    itemNum,
    unlockableContentId,
    eventId,
    votingId,
    partnerDealId,
  } = action.payload;
  try {
    const SwaggerClient = yield call(apiClient);
    let payload;
    if (unlockableContentId) {
      payload = yield call(
        SwaggerClient.apis.UnlockInputs.listUnlockInputsForUnlockableContent,
        {
          project_id: projectId,
          unlockable_content_id: unlockableContentId,
          page: pageNum,
          items: itemNum,
        },
        { requestInterceptor: applyHeaders(headers) }
      );
    } else if (eventId) {
      payload = yield call(
        SwaggerClient.apis.UnlockInputs.listUnlockInputsForEvent,
        {
          project_id: projectId,
          event_id: eventId,
          page: pageNum,
          items: itemNum,
        },
        { requestInterceptor: applyHeaders(headers) }
      );
    } else if (partnerDealId) {
      payload = yield call(
        SwaggerClient.apis.UnlockInputs.listUnlockInputsForPartnerDeal,
        {
          project_id: projectId,
          partner_deal_id: partnerDealId,
          page: pageNum,
          items: itemNum,
        },
        { requestInterceptor: applyHeaders(headers) }
      );
    } else if (votingId) {
      payload = yield call(
        SwaggerClient.apis.UnlockInputs.listUnlockInputsForVoting,
        {
          project_id: projectId,
          voting_id: votingId,
          page: pageNum,
          items: itemNum,
        },
        { requestInterceptor: applyHeaders(headers) }
      );
    } else {
      throw new Error("Unkown Resource");
    }

    if (payload.obj.included)
      yield* storeIncluded({ payload: payload.obj.included });
    yield put({ type: actions.listSuccess, payload: payload });
  } catch (e) {
    yield put({ type: actions.listFail, payload: e });
  }
}

/** Show Saga
 *  @description: connects to the showUnlockableContent operation
 *  @param {number} action.payload the UnlockableContent id
 */
export function* show(action) {
  let headers = yield buildHeaders();
  const { projectId, id } = action.payload;
  try {
    const SwaggerClient = yield call(apiClient);
    const payload = yield call(
      SwaggerClient.apis.UnlockInputs.showUnlockInputForProject,
      {
        project_id: projectId,
        id: id,
      },
      {
        requestInterceptor: applyHeaders(headers),
      }
    );
    if (payload.obj?.included)
      yield* storeIncluded({ payload: payload.obj.included });
    yield put({ type: actions.showSuccess, payload: payload.obj.data });
  } catch (e) {
    yield put({ type: actions.showFail, payload: e });
  }
}

/**
 * Saga Watchers
 * The exported list of sagas registered. When one of the action types is dispatched
 * the related worker saga is invoked.
 * Each saga is executed in a different thread
 */
function* unlockableBoundariesSaga() {
  yield takeEvery(actions.list, list);
  yield takeEvery(actions.show, show);
}
export default unlockableBoundariesSaga;
