import { MetaMask } from "@web3-react/metamask";
import { WalletConnect } from "@web3-react/walletconnect";
import { BowlineWallet } from "./lib/bowlineWallet";

export function getName(connector) {
  if (connector instanceof MetaMask) return "MetaMask";
  if (connector instanceof WalletConnect) return "WalletConnect";
  if (connector instanceof BowlineWallet) return "BowlineWallet";
  return "Unknown";
}
