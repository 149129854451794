import { call, put, takeEvery } from "redux-saga/effects";
import buildHeaders from "../../utils/buildHeaders";
import { actions } from "./index";
import { storeIncluded } from "../dictionarySagas";
import apiClient, { applyHeaders } from "../../utils/apiSwaggerRequest";

export function* create(action) {
  const { project_id, id, email, tokenId, address } = action.payload;
  let headers = yield buildHeaders();
  try {
    const SwaggerClient = yield call(apiClient);
    let payload = yield call(
      SwaggerClient.apis.Events.createEventRegistrationForEvent,
      { project_id: project_id, event_id: id },
      {
        requestInterceptor: applyHeaders(headers),
        requestBody: {
          email: email,
          token_id: tokenId,
          address: address,
        },
      }
    );

    if (payload.obj?.included)
      yield* storeIncluded({ payload: payload.obj.included });

    yield put({ type: actions.createSuccess, payload: payload.obj.data });
  } catch (e) {
    yield put({ type: actions.createFail, payload: e });
  }
}

/**
 * Saga Watchers
 * The exported list of sagas registered. When one of the action types is dispatched
 * the related worker saga is invoked.
 * Each saga is executed in a different thread
 */
function* eventRegistrationsSaga() {
  yield takeEvery(actions.create, create);
}

export default eventRegistrationsSaga;
