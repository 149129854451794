/**
 *  Sagas for the ResourceNames resource managment using Swagger client
 *
 * Each saga watcher intercepts a trigger action, does the asyncrhonous work in the respective worker saga and dispatches a success or a failure action.
 * Fetch calls are made via the swagger tags interface requests
 *
 */

import { call, put, takeEvery } from "redux-saga/effects";
import { actions } from "./index";
import { storeIncluded } from "../dictionarySagas";
import apiClient, { applyHeaders } from "../../utils/apiSwaggerRequest";
import buildHeaders from "../../utils/buildHeaders";

export function* list(action) {
  let headers = yield buildHeaders();
  let { projectId, pageNum, itemNum, sorting, filters } = action.payload;
  try {
    const SwaggerClient = yield call(apiClient);
    const payload = yield call(
      SwaggerClient.apis.Whitelists.listWhitelistEntriesForProject,
      {
        project_id: projectId,
        page: pageNum,
        items: itemNum,
        sorting: JSON.stringify(sorting),
        filters: JSON.stringify(filters),
      },
      {
        requestInterceptor: applyHeaders(headers),
      }
    );
    if (payload.obj?.included)
      yield* storeIncluded({ payload: payload.obj.included });
    yield put({ type: actions.listSuccess, payload: payload });
  } catch (e) {
    yield put({ type: actions.listFail, payload: e });
  }
}

export function* show(action) {
  const { projectId, address } = action.payload;
  let headers = yield buildHeaders();
  try {
    const SwaggerClient = yield call(apiClient);
    const payload = yield call(
      SwaggerClient.apis.Whitelists.showWhitelistEntryForProject,
      {
        project_id: projectId,
        address: address,
      },
      { requestInterceptor: applyHeaders(headers) }
    );
    if (payload.obj.included)
      yield* storeIncluded({ payload: payload.obj.included });
    yield put({ type: actions.showSuccess, payload: payload.obj.data });
  } catch (e) {
    yield put({ type: actions.showFail, payload: e });
  }
}

export function* create(action) {
  let headers = yield buildHeaders();
  let { projectId, account, callbackFnc } = action.payload;
  try {
    const SwaggerClient = yield call(apiClient);
    let payload = yield call(
      SwaggerClient.apis.Whitelists.createWhitelistEntryForProject,
      {
        project_id: projectId,
      },
      {
        requestInterceptor: applyHeaders(headers),
        requestBody: {
          wallet: {
            address: account,
          },
        },
      }
    );

    yield storeIncluded({ payload: payload.obj.included });
    yield put({ type: actions.createSuccess, payload: payload.obj.data });
    if (callbackFnc) callbackFnc();
  } catch (e) {
    yield put({ type: actions.createFail, payload: e });
  }
}

/**
 * Saga Watchers
 * The exported list of sagas registered. When one of the action types is dispatched
 * the related worker saga is invoked.
 * Each saga is executed in a different thread
 */
function* whitelistWalletsSaga() {
  yield takeEvery(actions.create, create);
  yield takeEvery(actions.list, list);
  yield takeEvery(actions.show, show);
}
export default whitelistWalletsSaga;
