/*
Replace
resourceName with your resource Name :) Take care about different writings!
/!\ ResourceName resourceName ResourceNames resourceNames /!\
Keep an eye on pluralized namings in here :)

*/

import { createSlice, createSelector } from "@reduxjs/toolkit";
import {
  defaultList,
  defaultListFail,
  defaultShow,
  defaultShowFail,
  defaultUpdate,
  defaultUpdateFail,
  defaultCreate,
  defaultCreateFail,
  defaultAddToDictionary,
  defaultListSuccess,
} from "../defaultReducers";
import { defaultInitialState } from "../defaultInitialState";

export const traitTypesSlice = createSlice({
  name: "traitTypes",
  initialState: { ...defaultInitialState, identifier_dictionary: {} },
  reducers: {
    list: defaultList,
    listSuccess: defaultListSuccess,
    // (state, action) => {
    //   const responseList = action.payload.obj.data || [];
    //   const responseIds = action.payload.obj.data.map((a) => a.id);
    //   const responseHeaders = action.payload.headers;

    //   if (responseHeaders && responseHeaders["total-count"])
    //     state.listTotalCount = parseInt(responseHeaders["total-count"], 10);
    //   if (responseHeaders && responseHeaders["current-page"])
    //     state.listPage = parseInt(responseHeaders["current-page"], 10);
    //   if (responseHeaders && responseHeaders["total-pages"])
    //     state.totalPages = parseInt(responseHeaders["total-pages"], 10);
    //   state.loadingList = false;
    //   state.loadedList = true;

    //   state.list = responseList;
    //   state.idsList = responseIds;

    // responseList.forEach((entry) => (state.dictionary[entry.id] = entry));
    // responseList.forEach(
    //   (entry) =>
    //     (state.identifier_dictionary[
    //       entry.attributes.contract_address +
    //         "/" +
    //         entry.attributes.identifier
    //     ] = entry.id)
    // );
    // },
    listFail: defaultListFail,
    show: defaultShow,
    showSuccess: (state, action) => {
      state.loadingShow = false;
      state.loadedShow = true;
      state.show = action.payload;
      state.dictionary[action.payload.id] = action.payload;
      state.identifier_dictionary[
        action.payload.attributes.contract_address +
          "/" +
          action.payload.attributes.identifier
      ] = action.payload.id;
    },
    showFail: defaultShowFail,
    create: defaultCreate,
    createSuccess: (state, action) => {
      state.creating = false;
      state.created = true;
      state.dictionary[action.payload.id] = action.payload;
      state.identifier_dictionary[
        action.payload.attributes.contract_address +
          "/" +
          action.payload.attributes.identifier
      ] = action.payload.id;
      state.createError = null;
    },
    createFail: defaultCreateFail,
    update: defaultUpdate,
    updateSuccess: (state, action) => {
      state.updating = false;
      state.updated = true;
      state.show = action.payload;
      state.dictionary[action.payload.id] = action.payload;
      state.identifier_dictionary[
        action.payload.attributes.contract_address +
          "/" +
          action.payload.attributes.identifier
      ] = action.payload.id;
    },
    updateFail: defaultUpdateFail,
    addToDictionary: defaultAddToDictionary,
  },
});
export const {
  show: showTraitType,
  list: listTraitTypes,
  create: createTraitType,
} = traitTypesSlice.actions;

export const actions = traitTypesSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const traitTypes = (state) =>
  state.traitTypes.idsList.map((id) => state.traitTypes.dictionary[id]);
export const trait = (state) =>
  state.traitTypes.showId &&
  state.traitTypes.dictionary[state.traitTypes.showId];
export const traitAttributes = (state) => trait(state)?.attributes;
export const traitRelationships = (state) => trait(state)?.relationships;
export const traitId = (state) => state.traitTypes.showId;

export const getTraitType = createSelector(
  (state) => state.traitTypes.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id];
  }
);

export const getTraitTypeAttributes = createSelector(
  (state) => state.traitTypes.dictionary,

  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id]?.attributes;
  }
);

export const getTraitTypeIdByIdentifier = createSelector(
  (state) => state.traitTypes.identifier_dictionary,

  (_, options) => options,
  (identifier_dictionary, { contractAddress, identifier }) => {
    return (
      contractAddress &&
      identifier &&
      identifier_dictionary &&
      identifier_dictionary[contractAddress + "/" + identifier]
    );
  }
);

export const getTraitTypeRelationships = createSelector(
  (state) => state.traitTypes.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id]?.relationships;
  }
);

export const getTraitTypes = createSelector(
  (state) => state.traitTypes.dictionary,
  (_, ids) => ids,
  (dictionary, ids) => {
    return ids && dictionary && ids.map((id) => dictionary[id]);
  }
);

export const getTraitsOptionsForTraitTypes = createSelector(
  (state) => state.traits.dictionary,
  (state) => state.traitTypes.dictionary,
  (traitsDictionary, traitTypesDictionary) => {
    const traitOptions = {};
    Object.keys(traitTypesDictionary).map((id) => {
      let options = [];

      const traitType = traitTypesDictionary[id];
      const traitTypeRelationships = traitType.relationships;
      if (traitTypeRelationships) {
        const traits = traitTypeRelationships.traits;
        if (traits) {
          traits.data.forEach((trait) => {
            const traitData = traitsDictionary[trait.id];
            if (
              !options.find(
                (option) => option.label === traitData?.attributes?.value
              )
            ) {
              options.push({
                label: traitData?.attributes?.value,
                value: traitData?.id,
              });
            }
          });
        }
      }
      traitOptions[id] = options;
    });

    return traitOptions;
  }
);

export default traitTypesSlice.reducer;
