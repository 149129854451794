// import { injected } from "./utils/Web3Connectors";
import React, { useEffect } from "react";
// import { Card, CardBody, CardTitle } from "reactstrap";
import PropTypes from "prop-types";
import { Button } from "reactstrap";
// import metamask from "../../assets/icons/metamask.svg";
import { metaMask } from "../../connectors/metaMask";
import { getAddChainParameters } from "../../connectors/chains";
import { REACT_APP_MAINNET } from "../../utils/env";

const MetamaskWalletConnectButton = (props, context) => {
  const { toggleModal } = props;
  //props from web 3 must be instantiated wherever function is used

  useEffect(() => {
    void metaMask.connectEagerly().catch(() => {
      console.debug("Failed to connect eagerly to metamask");
    });
    toggleModal && toggleModal(false);
  }, []);

  const handleConnect = () => {
    if (window.ethereum && window.ethereum.isMetaMask) {
      metaMask
        .activate(getAddChainParameters(REACT_APP_MAINNET ? 1 : 4))
        .then(() => console.log("connected"))
        .catch((err) => console.log(err));
      toggleModal && toggleModal(false);
    }
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      ) &&
      !(window.ethereum && window.ethereum.isMetaMask)
    ) {
      window.location =
        "https://metamask.app.link/dapp/" +
        window.location.host +
        window.location.pathname;
      console.log("Opening Mobile Wallet");
      return;
    }
  };

  // useEffect(() => {
  //   handleConnect();
  // }, []);

  return (
    <>
      <Button
        onClick={handleConnect}
        className="mb-3"
        color="primary"
        size="lg"
      >
        {context.t("Connect with Metamask")}
      </Button>
    </>
  );
};

/**  define proptype for the 'translation' function  */
MetamaskWalletConnectButton.contextTypes = {
  t: PropTypes.func,
};

/** define proptypes for the ConnectWallet */
MetamaskWalletConnectButton.propTypes = {
  toggleModal: PropTypes.func,
};

export default MetamaskWalletConnectButton;
