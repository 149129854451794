/** Worker Sagas */

import { call, put, takeEvery } from "redux-saga/effects";
import buildHeaders from "../../utils/buildHeaders";
import { actions } from "./index";
import { storeIncluded } from "../dictionarySagas";
import apiClient, { applyHeaders } from "../../utils/apiSwaggerRequest";

/** List Saga
 *  @description: connects to the getUnlockableContent operation
 */
export function* list(action) {
  let headers = yield buildHeaders();
  let { projectId, pageNum, itemNum, sorting, filters } = action.payload;
  try {
    const SwaggerClient = yield call(apiClient);
    const payload = yield call(
      SwaggerClient.apis.ContentCategories.listContentCategoriesForProject,
      {
        project_id: projectId,
        page: pageNum,
        items: itemNum,
        sorting: JSON.stringify(sorting),
        filters: JSON.stringify(filters),
      },
      {
        requestInterceptor: applyHeaders(headers),
      }
    );
    if (payload.obj.included)
      yield* storeIncluded({ payload: payload.obj.included });
    yield put({ type: actions.listSuccess, payload: payload });
  } catch (e) {
    yield put({ type: actions.listFail, payload: e });
  }
}

export function* create(action) {
  let headers = yield buildHeaders();
  let { projectId, name, status } = action.payload;
  try {
    const SwaggerClient = yield call(apiClient);
    let payload = yield call(
      SwaggerClient.apis.ContentCategories.createContentCategoryForProject,
      { project_id: projectId },
      {
        requestInterceptor: applyHeaders(headers),
        requestBody: {
          name: name,
          status: status,
        },
      }
    );
    if (payload.obj.included)
      yield* storeIncluded({ payload: payload.obj.included });
    yield put({ type: actions.createSuccess, payload: payload.obj.data });
  } catch (e) {
    yield put({ type: actions.createFail, payload: e });
  }
}

export function* update(action) {
  const { projectId, id, name, status } = action.payload;
  let headers = yield buildHeaders();
  try {
    const SwaggerClient = yield call(apiClient);
    let payload = yield call(
      SwaggerClient.apis.ContentCategories.updateContentCategoryForProject,
      { project_id: projectId, id: id },
      {
        requestInterceptor: applyHeaders(headers),
        requestBody: {
          name: name,
          status: status,
        },
      }
    );

    if (payload.obj?.included)
      yield* storeIncluded({ payload: payload.obj.included });

    yield put({ type: actions.updateSuccess, payload: payload.obj.data });
  } catch (e) {
    yield put({ type: actions.updateFail, payload: { mess: "ERROR" } });
  }
}

/**
 * Saga Watchers
 * The exported list of sagas registered. When one of the action types is dispatched
 * the related worker saga is invoked.
 * Each saga is executed in a different thread
 */
function* contentCategoriesSaga() {
  yield takeEvery(actions.list, list);
  yield takeEvery(actions.update, update);
  yield takeEvery(actions.create, create);
}
export default contentCategoriesSaga;
