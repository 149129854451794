/** Worker Sagas */

import { call, put, takeEvery } from "redux-saga/effects";
import buildHeaders from "../../utils/buildHeaders";
import { actions } from "./index";
import { storeIncluded } from "../dictionarySagas";
import apiClient, { applyHeaders } from "../../utils/apiSwaggerRequest";
import { actions as traitActions } from "../traits";
import { actions as signRequestActions } from "../signRequests/index";
import { v4 as uuidv4 } from "uuid";
import { getName } from "../../connectors/utils";
/** List Saga
 *  @description: connects to the getVoting operation
 */
export function* list(action) {
  let headers = yield buildHeaders();
  let { projectId, pageNum, itemNum, sorting, filters } = action.payload;
  try {
    const SwaggerClient = yield call(apiClient);
    const payload = yield call(
      SwaggerClient.apis.Votings.listVotingsForProject,
      {
        project_id: projectId,
        page: pageNum,
        items: itemNum,
        sorting: JSON.stringify(sorting),
        filters: JSON.stringify(filters),
      },
      { requestInterceptor: applyHeaders(headers) }
    );
    if (payload.obj.included)
      yield* storeIncluded({ payload: payload.obj.included });
    yield put({ type: actions.listSuccess, payload: payload });
  } catch (e) {
    yield put({ type: actions.listFail, payload: e });
  }
}

export function* create(action) {
  let headers = yield buildHeaders();
  let { project_id, ...requestBody } = action.payload;
  try {
    const SwaggerClient = yield call(apiClient);
    let payload = yield call(
      SwaggerClient.apis.Votings.createVotingForProject,
      { project_id: project_id },
      {
        requestInterceptor: applyHeaders(headers),
        requestBody,
      }
    );

    if (payload.obj?.included)
      yield* storeIncluded({ payload: payload.obj.included });
    yield put({ type: actions.createSuccess, payload: payload.obj.data });
  } catch (e) {
    yield put({ type: actions.createFail, payload: e });
  }
}
/** Show Saga
 *  @description: connects to the showVoting operation
 *  @param {number} action.payload the Voting id
 */
export function* show(action) {
  let headers = yield buildHeaders();
  const { projectId, id } = action.payload;
  try {
    const SwaggerClient = yield call(apiClient);
    const payload = yield call(
      SwaggerClient.apis.Votings.showVotingForProject,
      {
        project_id: projectId,
        id: id,
      },
      {
        requestInterceptor: applyHeaders(headers),
      }
    );
    if (payload.obj?.included)
      yield* storeIncluded({ payload: payload.obj.included });
    yield put({ type: actions.showSuccess, payload: payload.obj.data });
  } catch (e) {
    yield put({ type: actions.showFail, payload: e });
  }
}

export function* update(action) {
  const { project_id, id, ...requestBody } = action.payload;
  let headers = yield buildHeaders();
  let includedTraitsDictionary = {};
  let remainingIncluded = [];
  try {
    const SwaggerClient = yield call(apiClient);
    let payload = yield call(
      SwaggerClient.apis.Votings.updateVotingForProject,
      { project_id: project_id, id: id },
      {
        requestInterceptor: applyHeaders(headers),
        requestBody,
      }
    );

    if (payload.obj?.included)
      payload.obj.included.forEach((entry) => {
        if (entry.type === "trait") {
          includedTraitsDictionary[entry.id] = entry;
        } else {
          remainingIncluded.push(entry);
        }
      });
    yield* storeIncluded({ payload: remainingIncluded });
    yield put({
      type: traitActions.mergeDictionary,
      payload: includedTraitsDictionary,
    });

    yield put({ type: actions.updateSuccess, payload: payload.obj.data });
  } catch (e) {
    yield put({ type: actions.updateFail, payload: e });
  }
}

export function* unlock(action) {
  let headers = yield buildHeaders();
  let {
    project_id,
    id,
    tokens,
    unlock_boundary_id,
    unlock_input_values,
    signature,
    voting_option_id,
    callbackSuccess,
  } = action.payload;
  try {
    const SwaggerClient = yield call(apiClient);
    let payload = yield call(
      SwaggerClient.apis.Votings.unlockVotingForProject,
      { project_id: project_id, id: id },
      {
        requestInterceptor: applyHeaders(headers),
        requestBody: {
          signature,
          voting_option_id,
          tokens_attributes: tokens,
          unlock_boundary_id: unlock_boundary_id,
          unlock: {
            signature,
            voting_option_id,
            tokens_attributes: tokens,
            unlock_boundary_id: unlock_boundary_id,
            unlock_input_values: unlock_input_values,
          },
        },
      }
    );

    if (payload.obj?.included)
      yield* storeIncluded({ payload: payload.obj.included });
    // TODO: reevaluate if showSuccess is right to call here, but doesn't feel to wrong to store the data
    yield put({ type: actions.showSuccess, payload: payload.obj.data });
    yield put({ type: actions.unlockSuccess, payload: payload.obj.data });
    if (callbackSuccess) callbackSuccess();
  } catch (e) {
    yield put({ type: actions.unlockFail, payload: e });
  }
}

export function* prepareUnlock(action) {
  let {
    account,
    connector,
    provider,
    tokens,
    unlock_boundary_id,
    project_title,
    title,
    callbackSuccess,
    voting_option_id,
  } = action.payload;
  try {
    //  Maybe lets add an issued at to the message and use this date also for unlocked_at in the api
    const signer = provider.getSigner();
    const message = `I participate in the Voting "${title}" and vote on option ${voting_option_id}
with my NFTs:
${tokens
  .map((token) => `#${token.identifier} (Contract: ${token.contract_address})`)
  .join("\n")}

This message was signed with my Ethereum account:
${account}

Project: ${project_title}
URI: https://bowline.app
Version: 1
Chain ID: 1
`;

    console.log("message", message);
    const name = getName(connector);
    if (name !== "BowlineWallet") {
      const signature = yield signer.signMessage(message);

      yield put({ type: actions.prepareUnlockSuccess, payload: {} });
      callbackSuccess({
        signature,
        tokens,
        unlock_boundary_id,
      });
    } else {
      console.log("Signing with bowline wallet");
      let signaturePreview = "Unlock Voting";
      let messagePreview = {
        headline: `I participate in the Voting "${title}" and vote on option ${voting_option_id} with my NFTs:`,
        body: `${tokens
          .map(
            (token) =>
              `#${token.identifier} (Contract: ${token.contract_address})`
          )
          .join("\n")}`,
        terms: [
          `This message was signed with my Ethereum account: ${account}`,
          `Project: ${project_title}`,
          `URI: https://bowline.app`,
          `Version: 1`,
          `Chain ID: 1`,
        ],
      };
      yield put({
        type: signRequestActions.addToDictionary,
        payload: {
          id: uuidv4(),
          message,
          signaturePreview,
          messagePreview,
          signer,
          callbackSuccess: (sig) =>
            callbackSuccess({
              signature: sig,
              tokens,
              unlock_boundary_id,
            }),
          successAction: actions.prepareUnlockSuccess,
          failureAction: actions.prepareUnlockFail,
        },
      });
    }
  } catch (e) {
    yield put({ type: actions.prepareUnlockFail, payload: e });
  }
}

/**
 * Saga Watchers
 * The exported list of sagas registered. When one of the action types is dispatched
 * the related worker saga is invoked.
 * Each saga is executed in a different thread
 */
function* votingsSaga() {
  yield takeEvery(actions.list, list);
  yield takeEvery(actions.show, show);
  yield takeEvery(actions.update, update);
  yield takeEvery(actions.create, create);
  yield takeEvery(actions.prepareUnlock, prepareUnlock);
  yield takeEvery(actions.unlock, unlock);
}
export default votingsSaga;
