/*
Replace
resourceName with your resource Name :) Take care about different writings!
/!\ ResourceName resourceName ResourceNames resourceNames /!\
Keep an eye on pluralized namings in here :)
*/

import { createSlice, createSelector } from "@reduxjs/toolkit";
import {
  defaultShow,
  defaultShowFail,
  defaultShowSuccess,
  defaultAddToDictionary,
} from "../defaultReducers";
import { defaultInitialState } from "../defaultInitialState";
import Cookies from "universal-cookie";
const cookieHandler = new Cookies();

export const creatorsSlice = createSlice({
  name: "creators",
  initialState: {
    ...defaultInitialState,
    siweRunning: false,
    siweSuccess: false,
    siweError: null,
    currentCreatorId: null,
    prepareSiweRunning: false,
    prepareSiweSuccess: false,
    prepareSiweError: null,
  },
  reducers: {
    show: defaultShow,
    showSuccess: defaultShowSuccess,
    showFail: defaultShowFail,
    siwe: (state) => {
      state.siweRunning = true;
      state.siweSuccess = false;
      state.siweError = null;
      state.currentCreatorId = null;
    },
    siweSuccess: (state, action) => {
      state.siweRunning = false;
      state.siweSuccess = true;
      state.currentCreatorId = action.payload.id;
      state.dictionary[action.payload.id] = action.payload;
      state.siweError = null;
    },
    siweFail: (state, action) => {
      state.siweRunning = false;
      state.siweSuccess = false;
      state.currentCreatorId = null;
      state.siweError = action.payload.mess;
      cookieHandler.remove("creator-token", { sameSite: true, path: "/" });
    },
    siweLogout: (state, action) => {
      cookieHandler.remove("creator-token", { sameSite: true, path: "/" });
    },
    prepareSiwe: (state) => {
      state.prepareSiweRunning = true;
      state.prepareSiweSuccess = false;
      state.prepareSiweError = null;
      state.currentCreatorId = null;
      cookieHandler.remove("creator-token", { sameSite: true, path: "/" });
    },
    prepareSiweSuccess: (state, action) => {
      state.prepareSiweRunning = false;
      state.prepareSiweSuccess = true;
      state.prepareSiweError = null;
      cookieHandler.set("creator-token", action.payload.signature, {
        sameSite: true,
        path: "/",
      });
    },
    prepareSiweFail: (state, action) => {
      state.prepareSiweRunning = false;
      state.prepareSiweSuccess = false;
      state.currentCreatorId = null;
      state.prepareSiweError = action.payload.mess;
      cookieHandler.remove("creator-token", { sameSite: true, path: "/" });
    },
    addToDictionary: defaultAddToDictionary,
  },
});

export const {
  show: showCreator,
  siwe: creatorSiwe,
  siweLogout,
  prepareSiwe: prepareCreatorSiwe,
} = creatorsSlice.actions;

export const actions = creatorsSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const creators = (state) =>
  state.creators.idsList.map((id) => state.creators.dictionary[id]);
export const creator = (state) =>
  state.creators.showId && state.creators.dictionary[state.creators.showId];
export const creatorAttributes = (state) => creator(state)?.attributes;
export const creatorRelationships = (state) => creator(state)?.relationships;
export const creatorId = (state) => state.creators.showId;
export const currentCreatorId = (state) => state.creators.currentCreatorId;

export const getCreator = createSelector(
  (state) => state.creators.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id];
  }
);

export const getCreatorAttributes = createSelector(
  (state) => state.creators.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id]?.attributes;
  }
);

export const getCreatorRelationships = createSelector(
  (state) => state.creators.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id]?.relationships;
  }
);

export const getCreators = createSelector(
  (state) => state.creators.dictionary,
  (_, ids) => ids,
  (dictionary, ids) => {
    return ids && dictionary && ids.map((id) => dictionary[id]);
  }
);

export default creatorsSlice.reducer;
