/*
Replace
nft with your resource Name :) Take care about different writings!
/!\ Nft nft Nfts nfts /!\
Keep an eye on pluralized namings in here :)

*/

import { createSlice, createSelector } from "@reduxjs/toolkit";
import {
  defaultList,
  defaultListFail,
  defaultListSuccess,
  defaultShow,
  defaultShowFail,
  defaultShowSuccess,
  defaultUpdate,
  defaultUpdateSuccess,
  defaultUpdateFail,
  defaultCreate,
  defaultCreateSuccess,
  defaultCreateFail,
  defaultAddToDictionary,
} from "../defaultReducers";

import {
  defaultPrepareUnlock,
  defaultPrepareUnlockSuccess,
  defaultPrepareUnlockFail,
  defaultUnlock,
  defaultUnlockSuccess,
  defaultUnlockFail,
} from "../defaultUnlockableReducer";

import { defaultInitialState } from "../defaultInitialState";
import { unlockableInitialState } from "../unlockableInitialState";

export const votingsSlice = createSlice({
  name: "votings",
  initialState: { ...defaultInitialState, ...unlockableInitialState },
  reducers: {
    list: defaultList,
    listSuccess: defaultListSuccess,
    listFail: defaultListFail,
    show: defaultShow,
    showSuccess: defaultShowSuccess,
    showFail: defaultShowFail,
    create: defaultCreate,
    createSuccess: defaultCreateSuccess,
    createFail: defaultCreateFail,
    update: defaultUpdate,
    updateSuccess: defaultUpdateSuccess,
    updateFail: defaultUpdateFail,
    addToDictionary: defaultAddToDictionary,
    updateErrorReset: (state, action) => {
      state.updating = false;
      state.updated = false;
      state.updateError = null;
    },
    listErrorReset: (state, action) => {
      state.listing = false;
      state.listed = false;
      state.listError = null;
    },
    createErrorReset: (state, action) => {
      state.creating = false;
      state.created = false;
      state.createError = null;
    },
    showErrorReset: (state, action) => {
      state.showing = false;
      state.showed = false;
      state.showError = null;
      state.unlockError = null;
      state.unlockSuccess = false;
    },
    prepareUnlock: defaultPrepareUnlock,
    prepareUnlockSuccess: defaultPrepareUnlockSuccess,
    prepareUnlockFail: defaultPrepareUnlockFail,
    unlock: defaultUnlock,
    unlockSuccess: defaultUnlockSuccess,
    unlockFail: defaultUnlockFail,
  },
});

export const {
  show: showVoting,
  update: updateVoting,
  list: listVotings,
  create: createVoting,
  updateErrorReset: updateVotingErrorReset,
  unlock: unlockVoting,
  prepareUnlock: prepareUnlockVoting,
  listErrorReset: listVotingErrorReset,
  createErrorReset: createVotingErrorReset,
  showErrorReset: showVotingErrorReset,
} = votingsSlice.actions;
export const actions = votingsSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const votings = (state) =>
  state.votings.idsList.map((id) => state.votings.dictionary[id]);
export const voting = (state) =>
  state.votings.showId && state.votings.dictionary[state.votings.showId];
export const votingAttributes = (state) => voting(state)?.attributes;
export const votingRelationships = (state) => voting(state)?.relationships;
export const votingId = (state) => state.votings.showId;

export const getVoting = createSelector(
  (state) => state.votings.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id];
  }
);

export const getVotingAttributes = createSelector(
  (state) => state.votings.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id]?.attributes;
  }
);

export const getVotingRelationships = createSelector(
  (state) => state.votings.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id]?.relationships;
  }
);

export const getVotings = createSelector(
  (state) => state.votings.dictionary,
  (_, ids) => ids,
  (dictionary, ids) => {
    return ids && dictionary && ids.map((id) => dictionary[id]);
  }
);

export const getVotingFormData = createSelector(
  (state) => state.votings.dictionary,
  (state) => state.unlockBoundaries.dictionary,
  (state) => state.contracts.dictionary,
  (state) => state.tokens.dictionary,
  (state) => state.traits.dictionary,
  (state) => state.traitTypes.dictionary,
  (state) => state.traitTypesUnlockBoundaries.dictionary,
  (state) => state.votingOptions.dictionary,
  (_, id) => id,
  (
    votingDictionary,
    unlockBoundariesDictionary,
    contractDictionary,
    tokenDictionary,
    traitDictionary,
    traitTypeDictionary,
    traitTypeUnlockBoundaryDictionary,
    votingOptionDictionary,
    id
  ) => {
    let traitOptions = Object.keys(traitDictionary)?.map((key) => ({
      value: traitDictionary[key]?.id,
      label: traitDictionary[key]?.attributes?.value,
    }));
    let voting = votingDictionary[id];
    let votingData = { ...voting?.attributes };
    let votingOptionsIds = voting?.relationships?.voting_options?.data?.map(
      (option) => option?.id
    );
    votingData["voting_options_attributes"] = votingOptionsIds?.map(
      (optionId) => ({
        name: votingOptionDictionary[optionId]?.attributes?.name,
        id: optionId,
      })
    );
    if (!votingData?.voting_options_attributes) {
      votingData.voting_options_attributes = [{ name: "" }, { name: "" }];
    }
    let unlockBoundariesIds =
      voting?.relationships?.unlock_boundaries?.data?.map(
        (option) => option?.id
      );
    votingData["unlock_boundaries_attributes"] = unlockBoundariesIds?.map(
      (optionId) => {
        let traitTypeUnlockBoundaryIds = unlockBoundariesDictionary[
          optionId
        ]?.relationships?.trait_types_unlock_boundaries?.data?.map(
          (ttub) => ttub.id
        );
        let token =
          tokenDictionary[
            unlockBoundariesDictionary[optionId]?.attributes?.token_id
          ];
        return {
          id: optionId,
          currentBoundaryId: optionId,
          contract_id: {
            label:
              contractDictionary[
                unlockBoundariesDictionary[optionId]?.attributes?.contract_id
              ]?.attributes?.address,
            value:
              unlockBoundariesDictionary[optionId]?.attributes?.contract_id,
          },
          token_id: token
            ? {
                label: token?.attributes?.name,
                value: token?.id,
                image: JSON.parse(token?.attributes?.metadata)?.image || "",
                id: token?.attributes?.identifier,
              }
            : null,
          matching_tokens_count:
            unlockBoundariesDictionary[optionId]?.attributes
              ?.matching_tokens_count,
          unlock_boundary_limit: {
            label:
              unlockBoundariesDictionary[optionId]?.attributes
                ?.unlock_boundary_limit,
            value:
              unlockBoundariesDictionary[optionId]?.attributes
                ?.unlock_boundary_limit,
          },
          valid_from:
            unlockBoundariesDictionary[optionId]?.attributes?.valid_from,
          valid_until:
            unlockBoundariesDictionary[optionId]?.attributes?.valid_until,
          trait_types_unlock_boundaries_attributes:
            traitTypeUnlockBoundaryIds?.map((ttbId) => {
              let traitTypeValue =
                traitTypeUnlockBoundaryDictionary[ttbId]?.attributes
                  ?.trait_type_id;
              let traitTypeLabel =
                traitTypeDictionary[traitTypeValue]?.attributes?.name;
              let valueOption = traitOptions?.find(
                (opt) =>
                  opt.label ===
                  traitTypeUnlockBoundaryDictionary[ttbId]?.attributes?.value
              );
              return {
                trait_type_id: {
                  label: traitTypeLabel,
                  value: traitTypeValue,
                },
                value: valueOption,
                id: ttbId || undefined,
              };
            }),
        };
      }
    );
    return votingData;
  }
);

export default votingsSlice.reducer;
