export const defaultPrepareUnlock = (state, action) => {
  state.prepareUnlockError = null;
  state.preparingUnlock = true;
  state.preparedUnlock = false;
};
export const defaultPrepareUnlockSuccess = (state, action) => {
  state.preparingUnlock = false;
  state.preparedUnlock = true;
};
export const defaultPrepareUnlockFail = (state, action) => {
  state.preparingUnlock = false;
  state.preparedUnlock = false;
  state.prepareUnlockError = action.payload?.response?.obj?.data;
};
export const defaultUnlock = (state, action) => {
  state.unlockSuccess = false;
  state.unlocking = true;
  state.unlocked = false;
  state.unlockError = null;
};
export const defaultUnlockSuccess = (state, action) => {
  state.unlockSuccess = true;
  state.unlocking = false;
  state.unlocked = true;
};
export const defaultUnlockFail = (state, action) => {
  state.unlocking = true;
  state.unlocked = false;
  state.unlockError = action.payload?.response?.obj?.data;
  state.unlockSuccess = false;
};
