/**
 * The ProjectOnboarding component
 */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Modal,
  ModalBody,
  FormGroup,
  Input,
  Label,
  Container,
  Row,
  Col,
  FormText,
  Collapse,
  ModalHeader,
  ModalFooter,
} from "reactstrap";
import { useWeb3React } from "@web3-react/core";
import { getProjectAttributes } from "../../redux/projects";
import {
  getWalletAttributes,
  updateWallet,
  registerProject,
} from "../../redux/wallets";
import { REACT_APP_PROJECT_ID } from "../../utils/env";

// import { actions as walletActions } from "../../redux/wallets";
/**
 * Stateless react component to render the ProjectOnboarding component
 * @param {Object} props the props passed to the component.
 */
const ProjectOnboarding = (props, context) => {
  const { connector } = useWeb3React();
  const userInfo = connector ? connector.userInfo : null;
  const dispatch = useDispatch();
  const signedInWithEthereum = useSelector(
    (state) => state.wallets.siweSuccess
  );

  const projectData = useSelector((state) =>
    getProjectAttributes(state, REACT_APP_PROJECT_ID)
  );

  const projectConstants = useSelector((state) => state.constants.project);

  const currentWalletId = useSelector((state) => state.wallets.currentWalletId);

  const currentWalletData = useSelector((state) =>
    getWalletAttributes(state, currentWalletId)
  );

  const [showWizzard, setShowWizzard] = useState(false);
  const [projectTosModal, setProjectTosModal] = useState(false);
  const toggleProjectTosModal = () => setProjectTosModal(!projectTosModal);
  const [projectDpModal, setProjectDpModal] = useState(false);
  const toggleProjectDpModal = () => setProjectDpModal(!projectDpModal);
  const [projectEmail, setProjectEmail] = useState("");
  const [projectEmailShareMode, setProjectEmailShareMode] = useState("");
  const [projectEmailAnonymised, setProjectEmailAnonymised] = useState(true);
  const [projectShareEmail, setProjectShareEmail] = useState(false);
  const [bowlineMail, setBowlineMail] = useState("");

  useEffect(() => {
    if (currentWalletData?.email_masked) {
      setBowlineMail(currentWalletData.email_masked);
    }
    setShowWizzard(currentWalletData?.project_settings?.show_welcome);
  }, [currentWalletData]);

  useEffect(() => {
    if (
      [
        projectConstants.emailSharingMode.anyRequired,
        projectConstants.emailSharingMode.realRequired,
      ].includes(projectData?.email_sharing_mode)
    ) {
      setProjectShareEmail(true);
    }
    if (
      [projectConstants.emailSharingMode.realRequired].includes(
        projectData?.email_sharing_mode
      )
    ) {
      setProjectEmailAnonymised(false);
    }
  }, [projectData, projectConstants]);

  useEffect(() => {
    if (userInfo?.email) {
      setProjectEmail(userInfo.email);
    }
  }, [userInfo]);

  const dispatchRegisterProject = () => {
    dispatch(
      registerProject({
        projectId: REACT_APP_PROJECT_ID,
        address: currentWalletId,
        wallet: {
          project_tos_accepted: projectData?.terms_of_service ? true : null,
          project_dp_accepted: projectData?.data_privacy ? true : null,
          enabled: projectShareEmail,
          share_real_email: !projectEmailAnonymised,
        },
      })
    );
  };

  const handleProjectSubmission = () => {
    if (projectEmail) {
      dispatch(
        updateWallet({
          projectId: REACT_APP_PROJECT_ID,
          address: currentWalletId,
          wallet: {
            email: projectEmail,
          },
          callbackFnc: () => dispatchRegisterProject(),
        })
      );
    } else {
      dispatchRegisterProject();
    }

    setShowWizzard(false);
  };

  const handleEmailChange = (e) => {
    if (["share-email-anonymised"].includes(e.target.value)) {
      setProjectEmailAnonymised(true);
      setProjectShareEmail(true);
    }
    if (["share-email-unanonymised"].includes(e.target.value)) {
      setProjectEmailAnonymised(false);
      setProjectShareEmail(true);
    }
    if (["dont-share-email"].includes(e.target.value)) {
      setProjectEmailAnonymised(true);
      setProjectShareEmail(false);
    }
    setProjectEmailShareMode(e.target.value);
  };

  if (!currentWalletData) {
    return null;
  }

  const submitDisabled =
    (![
      projectConstants.emailSharingMode.disabled,
      projectConstants.emailSharingMode.realRequired,
    ].includes(projectData?.email_sharing_mode) &&
      projectEmailShareMode === "") ||
    (projectData?.email_sharing_mode ===
      projectConstants.emailSharingMode.realRequired &&
      !(projectEmail || bowlineMail)) ||
    (projectShareEmail && !(projectEmail || bowlineMail));

  return (
    <>
      <Modal
        isOpen={showWizzard && signedInWithEthereum}
        className="onboardingwizzard"
        fullscreen="md"
        size="xl"
        centered
        scrollable
      >
        <ModalBody className="pt-md-5 pb-md-5">
          <Container className="h-100">
            <Row className="h-100">
              <Col md="8">
                <h1>
                  👋{" "}
                  {context.t("Welcome to {project}", {
                    project: projectData?.title,
                  })}
                </h1>
                <div
                  dangerouslySetInnerHTML={{
                    __html: projectData?.welcome_text,
                  }}
                  className="mb-3"
                />
              </Col>
              <Col md={{ size: 4 }} className="d-flex flex-column">
                <div>
                  <h2>{context.t("Settings")}</h2>
                  {[
                    projectConstants.emailSharingMode.notRequired,
                    projectConstants.emailSharingMode.anyRequired,
                    projectConstants.emailSharingMode.realRequired,
                  ].includes(projectData.email_sharing_mode) && (
                    <>
                      <p className="mt-4">
                        {projectData.email_sharing_mode ===
                        projectConstants.emailSharingMode.notRequired
                          ? context.t("{project} asks for your email address", {
                              project: projectData?.title,
                            })
                          : context.t("{project} needs your email address", {
                              project: projectData?.title,
                            })}
                      </p>
                      <FormGroup tag="fieldset">
                        {projectData.email_sharing_mode !==
                        projectConstants.emailSharingMode.realRequired ? (
                          <>
                            <FormGroup check>
                              <Input
                                type="radio"
                                name="project-email"
                                id="share-email-anonymised"
                                value="share-email-anonymised"
                                onChange={(evt) => handleEmailChange(evt)}
                              />
                              <Label for="share-email-anonymised">
                                {context.t("share email anonymised")}{" "}
                                {context.t("(recommended)")}
                              </Label>
                            </FormGroup>
                            <Collapse
                              className="pb-3"
                              isOpen={
                                projectEmailShareMode ===
                                "share-email-anonymised"
                              }
                            >
                              <FormText>
                                {context.t(
                                  "{project} will only receive an anonymised email address but bowline will keep track of your real email address.",
                                  {
                                    project: projectData?.title,
                                  }
                                )}
                              </FormText>
                            </Collapse>
                            <FormGroup check>
                              <Input
                                type="radio"
                                name="project-email"
                                id="share-email-unanonymised"
                                value="share-email-unanonymised"
                                onChange={(evt) => handleEmailChange(evt)}
                              />
                              <Label for="share-email-unanonymised">
                                {context.t("share email")}
                              </Label>
                            </FormGroup>
                            <Collapse
                              className="pb-3"
                              isOpen={
                                projectEmailShareMode ===
                                "share-email-unanonymised"
                              }
                            >
                              <FormText>
                                {context.t(
                                  "{project} will get your email address and bowline will also store your email address.",
                                  {
                                    project: projectData?.title,
                                  }
                                )}
                              </FormText>
                            </Collapse>
                          </>
                        ) : (
                          <FormText>
                            {context.t(
                              "{project} will get your email address and bowline will also store your email address.",
                              {
                                project: projectData?.title,
                              }
                            )}
                          </FormText>
                        )}
                        {projectData.email_sharing_mode ===
                          projectConstants.emailSharingMode.notRequired && (
                          <FormGroup check>
                            <Input
                              type="radio"
                              name="project-email"
                              id="dont-share-email"
                              value="dont-share-email"
                              onChange={(evt) => handleEmailChange(evt)}
                            />
                            <Label for="dont-share-email">
                              {context.t("don't share email")}
                            </Label>
                          </FormGroup>
                        )}
                      </FormGroup>
                    </>
                  )}
                </div>
                {projectData?.email_sharing_mode !==
                  projectConstants.emailSharingMode.disabled &&
                  !bowlineMail && (
                    <div className="mb-3">
                      <FormGroup floating>
                        <Input
                          type="email"
                          id="project-email"
                          value={projectEmail}
                          onChange={(e) =>
                            setProjectEmail(e.currentTarget.value)
                          }
                          placeholder={context.t("Email address")}
                        />
                        <Label for="project-email">
                          {context.t("Email address")}
                        </Label>
                      </FormGroup>
                    </div>
                  )}
                {projectData?.email_sharing_mode !==
                  projectConstants.emailSharingMode.disabled &&
                  bowlineMail && (
                    <div className="mb-3">
                      <p>{context.t("Your stored email")}</p>
                      <p>{bowlineMail}</p>
                    </div>
                  )}
                <>
                  {(!!projectData?.terms_of_service ||
                    !!projectData?.data_privacy) && (
                    <>
                      <p className="small">
                        {!!projectData?.terms_of_service && (
                          <>
                            <Button
                              color="link"
                              onClick={toggleProjectTosModal}
                              className="p-0 pb-1"
                            >
                              {context.t("terms of service")}
                            </Button>
                            <Modal
                              isOpen={projectTosModal}
                              centered
                              scrollable
                              toggle={toggleProjectTosModal}
                            >
                              <ModalHeader toggle={toggleProjectTosModal}>
                                {context.t("terms of service")}
                              </ModalHeader>
                              <ModalBody>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: projectData?.terms_of_service,
                                  }}
                                />
                              </ModalBody>
                              <ModalFooter>
                                <Button
                                  color="primary"
                                  onClick={toggleProjectTosModal}
                                >
                                  {context.t("Close")}
                                </Button>
                              </ModalFooter>
                            </Modal>
                          </>
                        )}
                        {!!projectData?.terms_of_service &&
                          !!projectData?.data_privacy && <> & </>}
                        {!!projectData?.data_privacy && (
                          <>
                            <Button
                              color="link"
                              onClick={toggleProjectDpModal}
                              className="p-0 pb-1"
                            >
                              {context.t("data protection policy")}
                            </Button>
                            <Modal
                              isOpen={projectDpModal}
                              centered
                              scrollable
                              toggle={toggleProjectDpModal}
                            >
                              <ModalHeader toggle={toggleProjectDpModal}>
                                {context.t("data protection policy")}
                              </ModalHeader>
                              <ModalBody>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: projectData?.data_privacy,
                                  }}
                                />
                              </ModalBody>
                              <ModalFooter>
                                <Button
                                  color="primary"
                                  onClick={toggleProjectDpModal}
                                >
                                  {context.t("Close")}
                                </Button>
                              </ModalFooter>
                            </Modal>
                          </>
                        )}
                      </p>
                    </>
                  )}
                </>
                <div className="text-center mt-auto mb-3">
                  <Button
                    color="primary"
                    className="w-50"
                    onClick={handleProjectSubmission}
                    // TODO validate email format
                    disabled={submitDisabled}
                  >
                    {context.t("Submit")}
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        </ModalBody>
      </Modal>
    </>
  );
};

/**  define proptype for the 'translation' function  */
ProjectOnboarding.contextTypes = {
  t: PropTypes.func,
};

ProjectOnboarding.propTypes = {};

export default ProjectOnboarding;
