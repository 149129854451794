/**
 * The BowlineWallet component
 */
import React from "react";
import PropTypes from "prop-types";
import { useState } from "react";
import { useWeb3React } from "@web3-react/core";
import { Button } from "reactstrap";
import QRCode from "react-qr-code";
import Timer from "../Timer";

/**
 * Stateless react component to render the BowlineWallet component
 * @param {Object} props the props passed to the component.
 */
const WalletExport = function (props, context) {
  const { account, provider } = useWeb3React();

  const [walletKey, setWalletKey] = useState(null);
  const exportSeconds = 10;

  const handleExportWallet = async () => {
    if (account && provider?.provider) {
      let wkey = await provider.provider.request({
        method: "eth_private_key",
      });
      if (wkey) {
        setWalletKey(wkey);
        setTimeout(() => {
          setWalletKey(null);
        }, exportSeconds * 1000);
      }
    }
  };

  return (
    <>
      {account && provider?.provider && (
        <Button color="link mb-1" onClick={handleExportWallet}>
          {context.t("Export Wallet")}
        </Button>
      )}
      <div className={`d-flex justify-content-center`}>
        {!walletKey && (
          <p className="small">
            {context.t("(A QR code will be generated for {n} seconds)", {
              n: exportSeconds,
            })}
          </p>
        )}
      </div>
      {/* <div className={`d-flex justify-content-center`}></div> */}
      <div
        className={`d-flex justify-content-center w-50 ${
          walletKey === null ? "bg-dark p-0" : "bg-light p-4"
        }`}
      >
        {walletKey && <QRCode value={`0x${walletKey}`} size="100" />}
      </div>{" "}
      {walletKey && (
        <p className="small mt-2">
          {context.t(
            "Please scan the QR code using your phone camera or wallet app (e.g. Metamask) to export your wallet ."
          )}
        </p>
      )}
      {walletKey && <Timer startSeconds={exportSeconds} />}
    </>
  );
};

/**  define proptype for the 'translation' function  */
WalletExport.contextTypes = {
  t: PropTypes.func,
};

WalletExport.propTypes = {};

export default WalletExport;
