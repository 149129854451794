/*
Replace
resourceName with your resource Name :) Take care about different writings!
/!\ ResourceName resourceName ResourceNames resourceNames /!\
Keep an eye on pluralized namings in here :)

*/
/**
 *  Sagas for the ResourceNames resource managment using Swagger client
 *
 * Each saga watcher intercepts a trigger action, does the asyncrhonous work in the respective worker saga and dispatches a success or a failure action.
 * Fetch calls are made via the swagger tags interface requests
 *
 */

import { call, put, takeEvery } from "redux-saga/effects";
import buildHeaders from "../../utils/buildHeaders";
import { actions } from "./index";
import { storeIncluded } from "../dictionarySagas";
import apiClient, { applyHeaders } from "../../utils/apiSwaggerRequest";

/** Worker Sagas */

/** List Saga
 *  @description: connects to the getResourceName operation
 */
export function* list(action) {
  let headers = yield buildHeaders();

  try {
    const SwaggerClient = yield call(apiClient);
    const payload = yield call(
      SwaggerClient.apis.Projects.listProjects,
      {
        ...action.payload,
      },
      {
        requestInterceptor: applyHeaders(headers),
      }
    );

    if (payload.obj?.included)
      yield* storeIncluded({ payload: payload.obj.included });
    yield put({ type: actions.listSuccess, payload: payload });
  } catch (e) {
    yield put({ type: actions.listFail, payload: e });
  }
}

/** Show Saga
 *  @description: connects to the showResourceName operation
 *  @param {number} action.payload the ResourceName id
 */
export function* show(action) {
  let headers = yield buildHeaders();
  const projectId = action.payload;

  try {
    const SwaggerClient = yield call(apiClient);
    const payload = yield call(
      SwaggerClient.apis.Projects.showProject,
      { id: projectId },
      { requestInterceptor: applyHeaders(headers) }
    );
    yield* storeIncluded({ payload: payload.obj.included });
    yield put({ type: actions.showSuccess, payload: payload.obj.data });
  } catch (e) {
    yield put({ type: actions.showFail, payload: e });
  }
}

export function* create(action) {
  let headers = yield buildHeaders();
  let { title, contract } = action.payload;
  try {
    const SwaggerClient = yield call(apiClient);
    let payload = yield call(
      SwaggerClient.apis.Projects.createProject,
      {},
      {
        requestInterceptor: applyHeaders(headers),
        requestBody: {
          project: {
            title: title,
            nft_contract_address: contract,
          },
        },
      }
    );

    yield* storeIncluded({ payload: payload.obj.included });

    yield put({ type: actions.createSuccess, payload: payload.obj.data });
  } catch (e) {
    yield put({ type: actions.createFail, payload: e });
  }
}

/**
 * Saga Watchers
 * The exported list of sagas registered. When one of the action types is dispatched
 * the related worker saga is invoked.
 * Each saga is executed in a different thread
 */
function* projectSaga() {
  yield takeEvery(actions.list, list);
  yield takeEvery(actions.show, show);
  yield takeEvery(actions.create, create);
}
export default projectSaga;
