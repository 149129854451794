/**
 *  Sagas for the ResourceNames resource managment using Swagger client
 *
 * Each saga watcher intercepts a trigger action, does the asyncrhonous work in the respective worker saga and dispatches a success or a failure action.
 * Fetch calls are made via the swagger tags interface requests
 *
 */

import { call, put, takeEvery } from "redux-saga/effects";
import { actions } from "./index";
import { storeIncluded } from "../dictionarySagas";
import apiClient, { applyHeaders } from "../../utils/apiSwaggerRequest";
import buildHeaders from "../../utils/buildHeaders";
import { actions as traitActions } from "../traits";

export function* list(action) {
  let headers = yield buildHeaders();
  let { projectId, pageNum, itemNum, contract_address } = action.payload;
  let includedTraitsDictionary = {};
  let remainingIncluded = [];
  try {
    const SwaggerClient = yield call(apiClient);
    const payload = yield call(
      SwaggerClient.apis.TraitTypes.listTraitTypesForContract,
      {
        project_id: projectId,
        contract_address: contract_address,
        page: pageNum,
        items: itemNum,
      },
      { requestInterceptor: applyHeaders(headers) }
    );
    if (payload.obj?.included)
      payload.obj.included.forEach((entry) => {
        if (entry.type === "trait") {
          includedTraitsDictionary[entry.id] = entry;
        } else {
          remainingIncluded.push(entry);
        }
      });
    yield* storeIncluded({ payload: remainingIncluded });
    yield put({
      type: traitActions.mergeDictionary,
      payload: includedTraitsDictionary,
    });
    yield put({ type: actions.listSuccess, payload: payload });
  } catch (e) {
    yield put({ type: actions.listFail, payload: e });
  }
}

export function* show(action) {
  let headers = yield buildHeaders();
  const { projectId, identifier, contractAddress } = action.payload;

  try {
    const SwaggerClient = yield call(apiClient);
    const payload = yield call(
      SwaggerClient.apis.TraitTypes.showTraitTypeTypeForContract,
      {
        project_id: projectId,
        contract_address: contractAddress,
        identifier: identifier,
      },
      { requestInterceptor: applyHeaders(headers) }
    );
    if (payload.obj.included)
      yield* storeIncluded({ payload: payload.obj.included });
    yield put({ type: actions.showSuccess, payload: payload.obj.data });
  } catch (e) {
    yield put({ type: actions.showFail, payload: e });
  }
}

export function* create(action) {
  let headers = yield buildHeaders();
  let { projectId, contractAddress, callbackFnc, ...requestBody } =
    action.payload;
  try {
    const SwaggerClient = yield call(apiClient);
    let payload = yield call(
      SwaggerClient.apis.TraitTypes.createTraitTypeForContract,
      {
        project_id: projectId,
        contract_address: contractAddress,
      },
      {
        requestInterceptor: applyHeaders(headers),
        requestBody,
      }
    );

    yield storeIncluded({ payload: payload.obj.included });
    yield* put({ type: actions.createSuccess, payload: payload.obj.data });
    if (callbackFnc) callbackFnc();
  } catch (e) {
    yield put({ type: actions.createFail, payload: e });
  }
}

export function* update(action) {
  const { project_id, contractAddress, id, ...requestBody } = action.payload;
  let headers = yield buildHeaders();
  try {
    const SwaggerClient = yield call(apiClient);
    let payload = yield call(
      SwaggerClient.apis.TraitTypes.updateTraitTypeForContract,
      { project_id: project_id, id: id },
      {
        requestInterceptor: applyHeaders(headers),
        requestBody,
      }
    );

    if (payload.obj?.included)
      yield* storeIncluded({ payload: payload.obj.included });

    yield put({ type: actions.updateSuccess, payload: payload.obj.data });
  } catch (e) {
    yield put({ type: actions.updateFail, payload: e });
  }
}

/**
 * Saga Watchers
 * The exported list of sagas registered. When one of the action types is dispatched
 * the related worker saga is invoked.
 * Each saga is executed in a different thread
 */
function* traitTypesSaga() {
  yield takeEvery(actions.create, create);
  yield takeEvery(actions.list, list);
  yield takeEvery(actions.show, show);
}
export default traitTypesSaga;
