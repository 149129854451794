/**
 * The navbar container
 */
import React, { useState } from "react";
import { useWeb3React } from "@web3-react/core";
import classNames from "classnames";
import { Collapse, Nav, Navbar, NavbarToggler } from "reactstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { push } from "connected-react-router";
import { NavLink } from "react-router-dom";
import TrunkatedAddress from "../Utils/TrunkatedAddress";
import FlashAlert from "../FlashAlert";
import BowlineWallet from "../BowlineWallet";
import ConnectWallet from "../ConnectWallet";
import {
  REACT_APP_UNLOCKABLE_CONTENT_ID,
  REACT_APP_UNLOCKABLE_DOWNLOAD_CONTENT_ID,
} from "../../utils/env";

/**
 * Selects props from the store and passes them to the component
 * @param {Object} state the store
 */
const mapStateToProps = (state) => ({
  alertMessage: state.notifications.message,
  alertColor: state.notifications.color,
  alertTimeout: state.notifications.timeout,
  alertOrigin: state.notifications.origin,
  alertLink: state.notifications.link,
  lang: state.i18nState.lang,
  currentUser: state.account.currentUser,
  signedin: state.account.signedin,
});
/**
 * Binds dispatch method to the actions passed as props to the component
 */
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      navigateTo: push,
    },
    dispatch
  );

/**
 * The navigation bar container. Renders the top bar with links to navigte in the site
 */
const Navigationbar = (props, context) => {
  const { alertMessage, alertColor, alertTimeout, alertLink, alertOrigin } =
    props;
  const { account } = useWeb3React();

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  return (
    <div
      style={props.style}
      className={classNames("navigationbar ", {
        "navigationbar--sticky": props.isSticky,
      })}
    >
      <Navbar color="dark" expand="lg">
        <NavLink
          className="mx-auto text-white navbar-brand ms-3 ms-md-5"
          exact
          to="/"
          onClick={() => setIsOpen(false)}
        >
          <span className="h1">NFG</span>
        </NavLink>
        {/* <div className="vr text-light my-3 mx-5"></div> */}
        <NavbarToggler
          onClick={toggle}
          className="navbar-light me-3"
          // color="light"
        />
        <Collapse navbar isOpen={isOpen} className="bg-transparent">
          <Nav
            navbar
            className="me-auto w-100 d-flex justify-content-end align-items-center"
          >
            <NavLink
              className="text-decoration-none navlink text-white me-5 mb-3 mb-md-0"
              to={`/collections`}
              onClick={() => setIsOpen(false)}
            >
              {context.t("All Collections")}
            </NavLink>
            <NavLink
              className="text-decoration-none navlink text-white me-5 mb-3 mb-md-0"
              to={`/contents/${REACT_APP_UNLOCKABLE_DOWNLOAD_CONTENT_ID}`}
              onClick={() => setIsOpen(false)}
            >
              {context.t("Exclusive Contents")}
            </NavLink>
            <NavLink
              className="text-decoration-none navlink text-white me-5 mb-3 mb-md-0"
              to={`/contents/${REACT_APP_UNLOCKABLE_CONTENT_ID}`}
              onClick={() => setIsOpen(false)}
            >
              {context.t("NFG Raffle")}
            </NavLink>
            {/* <NavLink
              className="text-decoration-none navlink text-white me-5 mb-3 mb-md-0"
              to="/tokens"
              onClick={() => setIsOpen(false)}
            >
              {context.t("Token Gallery")}
            </NavLink> */}
            {!account ? (
              <ConnectWallet
                buttonColor="primary"
                buttonSize="sm"
                buttonId="nav-connect-wallet"
                className="bg-gradient border-0 text-white"
              />
            ) : (
              <span className="text-white py-3 px-3 fw-bold wallet-address me-5">
                <TrunkatedAddress address={account} />
                <BowlineWallet />
              </span>
            )}
          </Nav>
        </Collapse>
      </Navbar>
      {alertMessage && (
        <FlashAlert
          color={alertColor || "success"}
          timeout={alertTimeout}
          origin={alertOrigin}
          link={alertLink}
        >
          {alertMessage}
        </FlashAlert>
      )}
    </div>
  );
};

/**  define proptype for the 'translation' function  */
Navigationbar.contextTypes = {
  t: PropTypes.func,
};

Navigationbar.propTypes = {
  navigateTo: PropTypes.func,
  handleLogoutAction: PropTypes.func,
  lang: PropTypes.string,
  currentUser: PropTypes.object,
  signedin: PropTypes.bool,
  alertMessage: PropTypes.string,
  alertColor: PropTypes.string,
  alertTimeout: PropTypes.bool,
  isSticky: PropTypes.bool,
  style: PropTypes.object,
  alertLink: PropTypes.string,
  alertOrigin: PropTypes.string,
};

Navigationbar.defaultProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Navigationbar);
