/**
 * The navbar container
 */
import React from "react";
import PropTypes from "prop-types";
// import { Link } from "react-router-dom";
// import looksrare_logo from "../../assets/icons/looksrare.svg";
// import opensea_logo from "../../assets/icons/opensea.svg";
import discord_logo from "../../assets/icons/iconmonstr-discord-1.svg";
import instagram_logo from "../../assets/icons/iconmonstr-instagram-11.svg";
import twitter_logo from "../../assets/icons/iconmonstr-twitter-1.svg";
import logoWhite from "../../assets/icons/nfg-logo.svg";
import { Row, Col, Button } from "reactstrap";

/**
 * The navigation bar container. Renders the top bar with links to navigte in the site
 */
const Footer = (props, context) => {
  return (
    <div>
      <Row className="footer mt-5 d-flex justify-content-center border-top mx-5 mb-3">
        <Col
          xs={12}
          md={3}
          className="d-flex align-items-center justify-content-center my-3"
        >
          <img src={logoWhite} alt="nfg-logo" width={50} className="my-3" />
        </Col>
        <Col
          xs={12}
          md={3}
          className="d-flex align-items-center justify-content-center my-3"
        >
          <Button
            color="link"
            className="text-decoration-none text-white"
            size="sm"
            onClick={() => {
              window.location = "mailto:nft@art2people.digital";
            }}
          >
            {context.t("Contact Us")}
          </Button>
        </Col>
        <Col
          xs={12}
          md={3}
          className="d-flex  align-items-center justify-content-center  my-3"
        >
          <a
            href="https://discord.com/invite/UnDVkbkVFA"
            target="_blank"
            rel="noreferrer"
          >
            <img className="mx-3 logo" src={discord_logo} width={20} />
          </a>
          <a
            href="https://twitter.com/art2people_xyz"
            target="_blank"
            rel="noreferrer"
          >
            <img className="mx-3 logo" src={twitter_logo} width={20} />
          </a>
          <a
            href="https://www.instagram.com/art2people/"
            target="_blank"
            rel="noreferrer"
          >
            <img className="mx-3 logo" src={instagram_logo} width={20} />
          </a>
        </Col>
        <Col
          xs={12}
          md={3}
          className="d-flex align-items-center justify-content-center  my-3"
        >
          <a
            href="https://www.niftygateway.com/@art2people/collections"
            target="_blank"
            rel="noreferrer"
            title="art2people"
          >
            All our Collections
          </a>
        </Col>
      </Row>
    </div>
  );
};

/**  define proptype for the 'translation' function  */
Footer.contextTypes = {
  t: PropTypes.func,
};

export default Footer;
