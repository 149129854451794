/**
 * The ScrollToTop component
 */
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { isServer } from "../../../redux/store";

/**
 * React component to scroll to the top when navigating to a new route
 */
export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    if (!isServer) {
      const scrollOptions = {
        left: 0,
        top: 0,
        behavior: "auto",
      };
      if (window) window.scrollTo(scrollOptions);
    }
  }, [pathname]);

  return null;
}
