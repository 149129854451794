import React, { useEffect } from "react";
import { Button } from "reactstrap";
import PropTypes from "prop-types";
import { walletConnect } from "../../connectors/walletConnect";
// import walletconnect from "../../assets/icons/walletconnect.svg";
import { URI_AVAILABLE } from "@web3-react/walletconnect";
import { REACT_APP_MAINNET } from "../../utils/env";

const WalletConnectButton = (props, context) => {
  // log URI when available
  useEffect(() => {
    walletConnect.events.on(URI_AVAILABLE, (uri) => {
      console.log(`uri: ${uri}`);
    });
  }, []);

  // attempt to connect eagerly on mount
  // useEffect(() => {
  //   walletConnect.connectEagerly().catch(() => {
  //     console.debug("Failed to connect eagerly to walletconnect");
  //   });
  // }, []);

  const handleConnect = () => {
    walletConnect
      .activate(REACT_APP_MAINNET ? 1 : 4)
      .then(() => console.log("connected"))
      .catch((err) => console.log(err));
  };

  return (
    <>
      <Button
        onClick={handleConnect}
        className="mb-3"
        color="primary"
        size="lg"
      >
        {context.t("Connect with Wallet Connect")}
      </Button>
    </>
  );
};

/**  define proptype for the 'translation' function  */
WalletConnectButton.contextTypes = {
  t: PropTypes.func,
};

export default WalletConnectButton;
