/*
Replace
resourceName with your resource Name :) Take care about different writings!
/!\ ResourceName resourceName ResourceNames resourceNames /!\
Keep an eye on pluralized namings in here :)

*/

import { createSlice, createSelector } from "@reduxjs/toolkit";
import {
  defaultList,
  defaultListFail,
  defaultShow,
  defaultShowFail,
  defaultUpdate,
  defaultUpdateFail,
  defaultCreate,
  defaultCreateFail,
  defaultAddToDictionary,
} from "../defaultReducers";
import { defaultInitialState } from "../defaultInitialState";

export const unlockHistoryEventSlice = createSlice({
  name: "unlockHistoryEvents",
  initialState: { ...defaultInitialState, identifier_dictionary: {} },
  reducers: {
    list: defaultList,
    listSuccess: (state, action) => {
      const responseList = action.payload.obj.data || [];
      const responseIds = action.payload.obj.data.map((a) => a.id);
      const responseHeaders = action.payload.headers;

      if (responseHeaders && responseHeaders["total-count"])
        state.listTotalCount = parseInt(responseHeaders["total-count"], 10);
      if (responseHeaders && responseHeaders["current-page"])
        state.listPage = parseInt(responseHeaders["current-page"], 10);
      if (responseHeaders && responseHeaders["total-pages"])
        state.totalPages = parseInt(responseHeaders["total-pages"], 10);
      state.loadingList = false;
      state.loadedList = true;

      state.list = responseList;
      state.idsList = responseIds;

      responseList.forEach((entry) => (state.dictionary[entry.id] = entry));
      responseList.forEach(
        (entry) =>
          (state.identifier_dictionary[
            entry.attributes.contract_address +
              "/" +
              entry.attributes.identifier
          ] = entry.id)
      );
    },
    listFail: defaultListFail,
    show: defaultShow,
    showSuccess: (state, action) => {
      state.loadingShow = false;
      state.loadedShow = true;
      state.show = action.payload;
      state.dictionary[action.payload.id] = action.payload;
      state.identifier_dictionary[
        action.payload.attributes.contract_address +
          "/" +
          action.payload.attributes.identifier
      ] = action.payload.id;
    },
    showFail: defaultShowFail,
    create: defaultCreate,
    createSuccess: (state, action) => {
      state.creating = false;
      state.created = true;
      state.dictionary[action.payload.id] = action.payload;
      state.identifier_dictionary[
        action.payload.attributes.contract_address +
          "/" +
          action.payload.attributes.identifier
      ] = action.payload.id;
      state.createError = null;
    },
    createFail: defaultCreateFail,
    update: defaultUpdate,
    updateSuccess: (state, action) => {
      state.updating = false;
      state.updated = true;
      state.show = action.payload;
      state.dictionary[action.payload.id] = action.payload;
      state.identifier_dictionary[
        action.payload.attributes.contract_address +
          "/" +
          action.payload.attributes.identifier
      ] = action.payload.id;
    },
    updateFail: defaultUpdateFail,
    addToDictionary: defaultAddToDictionary,
    refreshMetadata: (state) => {
      state.refreshingMetedata = true;
      state.refreshedMetedata = false;
      state.refreshingMetedataError = null;
    },
    refreshMetadataSuccess: (state, action) => {
      state.refreshingMetedata = false;
      state.refreshedMetedata = true;
      state.refreshingMetedataError = null;
    },
    refreshMetadataFail: (state, action) => {
      state.refreshingMetedata = false;
      state.refreshedMetedata = false;
      state.refreshingMetedataError = action.payload.message;
    },
  },
});
export const {
  show: showUnlockHistoryEvent,
  list: listUnlockHistoryEvents,
  create: createUnlockHistoryEvent,
  refreshMetadata,
} = unlockHistoryEventSlice.actions;

export const actions = unlockHistoryEventSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const unlockHistoryEvents = (state) =>
  state.unlockHistoryEvents.idsList.map(
    (id) => state.unlockHistoryEvents.dictionary[id]
  );
export const unlockHistoryEvent = (state) =>
  state.unlockHistoryEvents.showId &&
  state.unlockHistoryEvents.dictionary[state.unlockHistoryEvents.showId];
export const unlockHistoryEventAttributes = (state) =>
  unlockHistoryEvent(state)?.attributes;
export const unlockHistoryEventRelationships = (state) =>
  unlockHistoryEvent(state)?.relationships;
export const unlockHistoryEventId = (state) => state.unlockHistoryEvents.showId;

export const getUnlockHistoryEvent = createSelector(
  (state) => state.unlockHistoryEvents.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id];
  }
);

export const getUnlockHistoryEventAttributes = createSelector(
  (state) => state.unlockHistoryEvents.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id]?.attributes;
  }
);

export const getUnlockHistoryEventIdByIdentifier = createSelector(
  (state) => state.unlockHistoryEvents.identifier_dictionary,

  (_, options) => options,
  (identifier_dictionary, { contractAddress, identifier }) => {
    return (
      contractAddress &&
      identifier &&
      identifier_dictionary &&
      identifier_dictionary[contractAddress + "/" + identifier]
    );
  }
);

export const getUnlockHistoryEventRelationships = createSelector(
  (state) => state.unlockHistoryEvents.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id]?.relationships;
  }
);

export const getUnlockHistoryEvents = createSelector(
  (state) => state.unlockHistoryEvents.dictionary,
  (_, ids) => ids,
  (dictionary, ids) => {
    return ids && dictionary && ids.map((id) => dictionary[id]);
  }
);

export default unlockHistoryEventSlice.reducer;
