import React from "react";
import PropTypes from "prop-types";

import MetamaskWalletConnectButton from "../../ConnectWalletButton/MetamaskWalletConnectButton-component";

const MetamaskConnectInfo = (props, context) => {
  const { toggleModal } = props;
  return (
    <div className="text-center d-flex flex-column">
      <p className="h4 text-center fw-bold mb-5">
        {context.t("Connect with MetaMask")}
      </p>
      <p className="my-4">
        {context.t(
          "MetaMask is a common and often used wallet that enables users to access the Web 3 ecosystem of decentralized applications."
        )}
      </p>
      <p className="mb-5">
        {context.t("Please use your existing MetaMask wallet.")}
      </p>
      <div>
        <MetamaskWalletConnectButton toggleModal={toggleModal} />
      </div>
      <a
        href="https://metamask.io/"
        target="_blank"
        rel="noreferrer"
        className="mt-5"
      >
        {context.t(
          `Don't have a metamask wallet yet? See here how to create it`
        )}
      </a>
    </div>
  );
};

/**  define proptype for the 'translation' function  */
MetamaskConnectInfo.contextTypes = {
  t: PropTypes.func,
};

/** define proptypes for the ConnectWallet */
MetamaskConnectInfo.propTypes = {
  toggleModal: PropTypes.func,
};

export default MetamaskConnectInfo;
