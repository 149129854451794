/**
 * The TransactionRequest component
 */
import React from "react";
import PropTypes from "prop-types";
import { Badge } from "reactstrap";
import { useEffect } from "react";
import { AiFillCheckCircle } from "react-icons/ai";
import { IoMdTimer } from "react-icons/io";
// import { MdError } from "react-icons/md";
import { useWeb3React } from "@web3-react/core";
import Moment from "react-moment";
import { useDispatch } from "react-redux";
import { addToDictionary } from "../../redux/transactionRequests";

// import { actions as walletActions } from "../../redux/wallets";
/**
 * Stateless react component to render the TransactionRequest component
 * @param {Object} props the props passed to the component.
 */
const TransactionRequest = (props) => {
  const { provider } = useWeb3React();
  console.log("provider", provider);
  const { transaction } = props;
  const dispatch = useDispatch();

  useEffect(async () => {
    if (transaction?.transactionRequestStatus === "pending") {
      const receipt = await provider.getTransactionReceipt(transaction.id);
      if (receipt && receipt.status) {
        dispatch(
          addToDictionary({
            ...transaction,
            transactionRequestStatus: "success",
          })
        );
      }
    }
  }, []);

  return (
    <>
      <div
        key={transaction.id}
        className="d-flex justify-content-between my-2 border border-primary p-2 rounded"
      >
        <span>{transaction.transactionPreview}</span>
        <span>
          <Moment fromNow>{transaction.createdAt}</Moment>
        </span>
        {transaction?.transactionRequestStatus === "success" && (
          <Badge size="sm" color="primary" className="ms-3">
            <AiFillCheckCircle />
          </Badge>
        )}
        {transaction?.transactionRequestStatus === "pending" && (
          <Badge size="sm" color="warning" className="ms-3">
            <IoMdTimer />
          </Badge>
        )}
      </div>
    </>
  );
};

/**  define proptype for the 'translation' function  */
TransactionRequest.contextTypes = {
  t: PropTypes.func,
};

TransactionRequest.propTypes = {
  transaction: PropTypes.object,
};

export default TransactionRequest;
