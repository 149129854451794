/*
Replace
nft with your resource Name :) Take care about different writings!
/!\ Nft nft Nfts nfts /!\
Keep an eye on pluralized namings in here :)

*/

import { createSlice, createSelector } from "@reduxjs/toolkit";
import {
  defaultList,
  defaultListFail,
  defaultListSuccess,
  defaultShow,
  defaultShowFail,
  defaultShowSuccess,
  defaultUpdate,
  defaultUpdateSuccess,
  defaultUpdateFail,
  defaultCreate,
  defaultCreateSuccess,
  defaultCreateFail,
  defaultAddToDictionary,
} from "../defaultReducers";
import { defaultInitialState } from "../defaultInitialState";

export const contentCategoriesSlice = createSlice({
  name: "contentCategories",
  initialState: { ...defaultInitialState },
  reducers: {
    list: defaultList,
    listSuccess: defaultListSuccess,
    listFail: defaultListFail,
    show: defaultShow,
    showSuccess: defaultShowSuccess,
    showFail: defaultShowFail,
    create: defaultCreate,
    createSuccess: defaultCreateSuccess,
    createFail: defaultCreateFail,
    update: defaultUpdate,
    updateSuccess: defaultUpdateSuccess,
    updateFail: defaultUpdateFail,
    addToDictionary: defaultAddToDictionary,
    updateReset: (state, action) => {
      state.updating = false;
      state.updated = false;
      state.updateError = null;
    },
    listReset: (state, action) => {
      state.listing = false;
      state.listed = false;
      state.listError = null;
    },
    createReset: (state, action) => {
      state.creating = false;
      state.created = false;
      state.createError = null;
    },
  },
});

export const {
  create: createContentCategory,
  update: updateContentCategory,
  list: listContentCategories,
  updateReset: updateContentCategoryReset,
  listReset: listContentCategoryReset,
  createReset: createContentCategoryReset,
} = contentCategoriesSlice.actions;
export const actions = contentCategoriesSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const contentCategories = (state) =>
  state.contentCategories.idsList.map(
    (id) => state.contentCategories.dictionary[id]
  );
export const unlockableContent = (state) =>
  state.contentCategories.showId &&
  state.contentCategories.dictionary[state.contentCategories.showId];
export const contentCategoryAttributes = (state) =>
  unlockableContent(state)?.attributes;
export const contentCategoryRelationships = (state) =>
  unlockableContent(state)?.relationships;
export const contentCategoryId = (state) => state.contentCategories.showId;

export const getContentCategory = createSelector(
  (state) => state.contentCategories.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id];
  }
);

export const getContentCategoryAttributes = createSelector(
  (state) => state.contentCategories.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id]?.attributes;
  }
);

export const getContentCategoryRelationships = createSelector(
  (state) => state.contentCategories.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id]?.relationships;
  }
);

export const getContentCategories = createSelector(
  (state) => state.contentCategories.dictionary,
  (_, ids) => ids,
  (dictionary, ids) => {
    return ids && dictionary && ids.map((id) => dictionary[id]);
  }
);

export const getContentCategoryOptions = (state) => {
  return Object.values(state.contentCategories.dictionary).map(
    (contentCategory) => ({
      value: contentCategory.id,
      label: contentCategory.attributes.name,
    })
  );
};

export default contentCategoriesSlice.reducer;
