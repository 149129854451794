/**
 * The redux store
 */
import { createBrowserHistory, createMemoryHistory } from "history";
import { createStore, applyMiddleware } from "redux";
import { routerMiddleware } from "connected-react-router";
import { composeWithDevTools } from "redux-devtools-extension";
import createSagaMiddleware, { END } from "redux-saga";
import thunk from "redux-thunk-fsa";

/** Logger with default options */
import logger from "redux-logger";
import createRootReducer from "./rootReducer";
import rootSaga from "./rootSaga";
import { saveState, loadState } from "../utils/localStorage";

/** A nice helper to tell us if we're on the server */
export const isServer = !(
  typeof window !== "undefined" &&
  window.document &&
  window.document.createElement
);

function updatePersistedState(state) {
  let currentState = state;
  if (!isServer) {
    if (window && window.__PRELOADED_STATE__)
      currentState = window.__PRELOADED_STATE__;
  }
  let newState = {};
  Object.keys(currentState).forEach((reducer) => {
    if (currentState[reducer].stored) {
      if (currentState[reducer].propertiesToStore) {
        let reducerStoredState = {
          propertiesToStore: currentState[reducer].propertiesToStore,
        };
        currentState[reducer].propertiesToStore.forEach((property) => {
          if (currentState[reducer][property])
            reducerStoredState[property] = currentState[reducer][property];
        });
        newState[reducer] = reducerStoredState;
      } else newState[reducer] = currentState[reducer];
    }
  });
  return newState;
}

/**
 * Configures the store globally
 * - applies middlewares
 * - applies routing history
 * - adds root sagas and reducers
 */
export default function configureStore(url = "/") {
  /** the persisted state */
  const persistedState = !isServer ? loadState() : {};

  /**  create the saga middleware */
  const sagaMiddleware = createSagaMiddleware();
  // set the server url to something without ? so we do not reuse the same goole gclid or something every time
  const url_split = url.split("?");
  const history = isServer
    ? createMemoryHistory({
        initialEntries: [url_split[0]],
      })
    : createBrowserHistory();

  // Dev tools are helpful
  // if (process.env.NODE_ENV === 'development' && !isServer) {
  //   const devToolsExtension = window.devToolsExtension;

  //   if (typeof devToolsExtension === 'function') {
  //     enhancers.push(devToolsExtension());
  //   }
  // }
  const middlewares = [sagaMiddleware, routerMiddleware(history), thunk];
  if (!isServer && process.env.NODE_ENV === "development")
    middlewares.push(logger);

  const store = createStore(
    createRootReducer(history),
    persistedState,
    composeWithDevTools(
      applyMiddleware(...middlewares)
      // other store enhancers if any
    )
  );
  // add here the parts of the store to persist
  store.subscribe(() => {
    const currentState = store.getState();
    let stateToSave = updatePersistedState(currentState);
    saveState(stateToSave);
  });

  store.runSaga = sagaMiddleware.run;
  store.close = () => store.dispatch(END);

  return { store, history, sagaMiddleware, rootSaga };
}
