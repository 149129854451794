import React from "react";
import PropTypes from "prop-types";

import WalletConnectButton from "../../ConnectWalletButton/WalletConnectButton-component";

const WalletConnectInfo = (props, context) => {
  return (
    <div className="text-center d-flex flex-column">
      <p className="h4 text-center fw-bold mb-5">
        {context.t("Connect with your preferred wallet")}
      </p>
      <p className="my-4">
        {context.t(
          "With WalletConnect you can connect to almost any existing wallet."
        )}
      </p>
      <p className="my-4">
        {context.t(
          "Please click on WalletConnect and either scan the QR code with the wallet of your choice or select from the given list of compatible wallets."
        )}
      </p>
      <div>
        <WalletConnectButton />
      </div>
    </div>
  );
};

/**  define proptype for the 'translation' function  */
WalletConnectInfo.contextTypes = {
  t: PropTypes.func,
};

export default WalletConnectInfo;
