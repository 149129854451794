import { createSlice } from "@reduxjs/toolkit";

export const constantsSlice = createSlice({
  name: "constants",
  initialState: {
    project: {
      emailSharingMode: {
        disabled: "disabled",
        notRequired: "not_required",
        anyRequired: "any_required",
        realRequired: "real_required",
      },
    },
  },
  reducers: {},
});

export default constantsSlice.reducer;
