/*
Replace
resourceName with your resource Name :) Take care about different writings!
/!\ ResourceName resourceName ResourceNames resourceNames /!\
Keep an eye on pluralized namings in here :)

*/

import { createSlice, createSelector } from "@reduxjs/toolkit";
import {
  defaultList,
  defaultListFail,
  defaultShow,
  defaultShowFail,
  defaultUpdate,
  defaultUpdateFail,
  defaultCreate,
  defaultCreateFail,
  defaultAddToDictionary,
  defaultListSuccess,
  defaultShowSuccess,
  defaultCreateSuccess,
  defaultUpdateSuccess,
} from "../defaultReducers";
import { defaultInitialState } from "../defaultInitialState";

export const mintTiersSlice = createSlice({
  name: "mintTiers",
  initialState: { ...defaultInitialState, identifier_dictionary: {} },
  reducers: {
    list: defaultList,
    listSuccess: defaultListSuccess,
    listFail: defaultListFail,
    show: defaultShow,
    showSuccess: defaultShowSuccess,
    showFail: defaultShowFail,
    create: defaultCreate,
    createSuccess: defaultCreateSuccess,
    createFail: defaultCreateFail,
    update: defaultUpdate,
    updateSuccess: defaultUpdateSuccess,
    updateFail: defaultUpdateFail,
    addToDictionary: defaultAddToDictionary,
    generateWertSignature: (state, action) => {
    },
    updateErrorReset: (state, action) => {
      state.updating = false;
      state.updated = false;
      state.updateError = null;
    },
    listErrorReset: (state, action) => {
      state.listing = false;
      state.listed = false;
      state.listError = null;
    },
    createErrorReset: (state, action) => {
      state.creating = false;
      state.created = false;
      state.createError = null;
    },
    showErrorReset: (state, action) => {
      state.showing = false;
      state.showed = false;
      state.showError = null;
    },
    listTiersForProject: (state, action) => {},
  },
});
export const {
  show: showMintTier,
  list: listMintTiers,
  listTiersForProject,
  create: createMintTier,
  update: updateMintTier,
  generateWertSignature,
  updateErrorReset: updateMintTierErrorReset,
  listErrorReset: listMintTierErrorReset,
  createErrorReset: createMintTierErrorReset,
  showErrorReset: showMintTierErrorReset,
} = mintTiersSlice.actions;

export const actions = mintTiersSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const mintTiers = (state) =>
  state.mintTiers.idsList.map((id) => state.mintTiers.dictionary[id]);
export const mintTier = (state) =>
  state.mintTiers.showId && state.mintTiers.dictionary[state.mintTiers.showId];
export const mintTierAttributes = (state) => mintTier(state)?.attributes;
export const mintTierRelationships = (state) => mintTier(state)?.relationships;
export const mintTierId = (state) => state.mintTiers.showId;

export const getMintTier = createSelector(
  (state) => state.mintTiers.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id];
  }
);

export const getMintTierAttributes = createSelector(
  (state) => state.mintTiers.dictionary,

  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id]?.attributes;
  }
);

export const getMintTierIdByIdentifier = createSelector(
  (state) => state.mintTiers.identifier_dictionary,

  (_, options) => options,
  (identifier_dictionary, { contractAddress, identifier }) => {
    return (
      contractAddress &&
      identifier &&
      identifier_dictionary &&
      identifier_dictionary[contractAddress + "/" + identifier]
    );
  }
);

export const getMintTierRelationships = createSelector(
  (state) => state.mintTiers.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id]?.relationships;
  }
);

export const getMintTiers = createSelector(
  (state) => state.mintTiers.dictionary,
  (_, ids) => ids,
  (dictionary, ids) => {
    return ids && dictionary && ids.map((id) => dictionary[id]);
  }
);

export const getMintTierIdsByContract = createSelector(
  (state) => state.mintTiers.dictionary,
  (_, contractAddress) => contractAddress,
  (dictionary, contractAddress) => {
    let mintTierIds = [];
    for (const id in dictionary) {
      if (dictionary[id]?.attributes?.contract_address === contractAddress) {
        mintTierIds.push(dictionary[id].id);
      }
    }
    return mintTierIds;
  }
);

export const getAvailableMintTierContracts = createSelector(
  (state) => state.mintTiers.dictionary,
  (_, ids) => ids,
  (dictionary, ids) => {
    return (
      ids &&
      dictionary &&
      ids
        .map((id) => dictionary[id]?.attributes?.contract_address)
        .filter((value, index, self) => self.indexOf(value) === index)
    );
  }
);

export default mintTiersSlice.reducer;
