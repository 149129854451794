/** Worker Sagas */

import { call, put, takeEvery } from "redux-saga/effects";
import buildHeaders from "../../utils/buildHeaders";
import { actions } from "./index";
import { storeIncluded } from "../dictionarySagas";
import apiClient, { applyHeaders } from "../../utils/apiSwaggerRequest";

/** List Saga
 *  @description: connects to the getVoting operation
 */
export function* list(action) {
  let headers = yield buildHeaders();
  let { projectId, pageNum, itemNum } = action.payload;
  try {
    const SwaggerClient = yield call(apiClient);
    const payload = yield call(
      SwaggerClient.apis.VotingOptions.listVotingOptionForProject,
      { project_id: projectId, page: pageNum, items: itemNum },
      { requestInterceptor: applyHeaders(headers) }
    );
    if (payload.obj.included)
      yield* storeIncluded({ payload: payload.obj.included });
    yield put({ type: actions.listSuccess, payload: payload });
  } catch (e) {
    yield put({ type: actions.listFail, payload: e });
  }
}

export function* create(action) {
  let headers = yield buildHeaders();
  let { project_id, title } = action.payload;
  try {
    const SwaggerClient = yield call(apiClient);
    let payload = yield call(
      SwaggerClient.apis.VotingOptions.createVotingOptionsForProject,
      { project_id: project_id },
      {
        requestInterceptor: applyHeaders(headers),
        requestBody: {
          title: title,
        },
      }
    );

    if (payload.obj?.included)
      yield* storeIncluded({ payload: payload.obj.included });
    yield put({ type: actions.createSuccess, payload: payload.obj.data });
  } catch (e) {
    yield put({ type: actions.createFail, payload: e });
  }
}
/** Show Saga
 *  @description: connects to the showVoting operation
 *  @param {number} action.payload the Voting id
 */
export function* show(action) {
  let headers = yield buildHeaders();
  const { projectId, id } = action.payload;
  try {
    const SwaggerClient = yield call(apiClient);
    const payload = yield call(
      SwaggerClient.apis.VotingOptions.showVotingOptionsForProject,
      {
        project_id: projectId,
        id: id,
      },
      {
        requestInterceptor: applyHeaders(headers),
      }
    );
    if (payload.obj?.included)
      yield* storeIncluded({ payload: payload.obj.included });
    yield put({ type: actions.showSuccess, payload: payload.obj.data });
  } catch (e) {
    yield put({ type: actions.showFail, payload: e });
  }
}

export function* update(action) {
  const { project_id, id, title } = action.payload;
  let headers = yield buildHeaders();
  try {
    const SwaggerClient = yield call(apiClient);
    let payload = yield call(
      SwaggerClient.apis.VotingOptions.updateVotingOptionsForProject,
      { project_id: project_id, id: id },
      {
        requestInterceptor: applyHeaders(headers),
        requestBody: {
          title: title,
        },
      }
    );

    if (payload.obj?.included)
      yield* storeIncluded({ payload: payload.obj.included });

    yield put({ type: actions.updateSuccess, payload: payload.obj.data });
  } catch (e) {
    yield put({ type: actions.updateFail, payload: e });
  }
}

/**
 * Saga Watchers
 * The exported list of sagas registered. When one of the action types is dispatched
 * the related worker saga is invoked.
 * Each saga is executed in a different thread
 */
function* votingOptionsSaga() {
  yield takeEvery(actions.list, list);
  yield takeEvery(actions.show, show);
  yield takeEvery(actions.update, update);
  yield takeEvery(actions.create, create);
}
export default votingOptionsSaga;
